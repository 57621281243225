import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import './App.scss';
import { Spinner } from 'react-bootstrap';
import ChangePassword from './components/login/changepassword.component';
import NotFoundComponent from './components/notFound.component';
import ForgotPassword from './components/login/forgotpassword.component'
import ResetPassword from './components/login/resetPassword.component';

const loading = (
	<div className="pt-3 text-center">
		<div className="sk-spinner sk-spinner-pulse">
			<Spinner animation="border" variant="success" />
		</div>
	</div>
)

const Dashboard = React.lazy(() => import('./components/dashboard/dashboard.component'));
const Settings = React.lazy(() => import('./components/settings/settings.component'));
const StoreSetupComponent = React.lazy(() => import('./components/stores/storeSetup.component'));
const SignupComponent = React.lazy(() => import('./components/login/Signup.component'));
const Login = React.lazy(() => import('./components/login/login.component'));
const Admin = React.lazy(() => import('./components/Admin/admin.component.js'))
const AdminLogin = React.lazy(() => import('./components/Admin/Admin_login/admin.login.js'))
const MarketingComponent = React.lazy(() => import('./components/marketing/marketing.component.js'))
const WhatsappLanding = React.lazy(() => import('./components/landingPages/whatsapp.js'))


const AdminUsers = React.lazy(() => import('./components/Admin/Admin_Users/admin.users.js'))
const AdminWhatsapp = React.lazy(() => import('./components/Admin/Admin_Whatsapp/admin.whatsapp.js'))
const AdminUserDetails = React.lazy(() => import('./components/Admin//Admin_User_details/admin.userDetails.js'))

class App extends Component {
	render() {
		return (
			<div className="App">
				<React.Suspense fallback={loading} >
					<Switch>
						<Route path="/" exact component={Dashboard} >
						</Route>
						{/* ---- Admin routes ------ */}
						<Route path="/admin3kw488kH">
							<Admin />
						</Route>

						<Route exact path="/admin3kw488/login">
							<AdminLogin />
						</Route>
						{/* ---- Admin routes ends ------ */}
						
						<Route path="/whatsapp">
							<WhatsappLanding />
						</Route>

						<Route path="/store-setup">
							<StoreSetupComponent />
						</Route>
						<Route path="/settings">
							<Settings />
						</Route>

						<Route path="/login">
							<Login />
						</Route>

						<Route path="/signup">
							<SignupComponent />
						</Route>

						<Route path="/forgotPassword">
							<ForgotPassword />
						</Route>
						<Route path="/resetPassword">
							<ResetPassword />
						</Route>

						<Route path={
							["/marketing",
								"/marketing/:channel",
							]} component={MarketingComponent} />

						<Route path={
							["/dashboard",
								"/dashboard/inbox",
								"/dashboard/inbox/:channelId",
								"/dashboard/inbox/:channelId/:ticketId",
								"/dashboard/inbox/:channelId/:ticketId/:ticketStatus",
							]} exact component={Dashboard} />

						<Route path="" component={NotFoundComponent} />

					</Switch>
				</React.Suspense>
			</div>
		);
	}
}

export default App;
